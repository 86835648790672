import {
  ANY_ORDER_COMPLETE,
  ANY_VACANCY,
  DASHBOARD,
  ME,
  MY_USER_PROFILE,
  PUBLICATION_STORE,
  PUBLISH_VACANCY,
  SELECT_WORKSPACE,
  STORE_COMPARISON_TABLE,
  STORE_ORDER_COMPLETE,
  STORE_SUMMARY,
  SUMMARY_ORDER_COMPLETE,
  VACANCY_LIST,
} from './layout-pages.helpers';

export const getGoBackValue = (
  prevUrl: string,
  currentUrl: string
): { route: string; title: string } | undefined => {
  const routeHash = createRouteHash(mapUrlToPageName(prevUrl), mapUrlToPageName(currentUrl));
  return goBackMap[routeHash];
};

const goBackMap = {
  [`${SELECT_WORKSPACE}-${ME}`]: {
    route: SELECT_WORKSPACE,
    // this is still unreleased, we'll add translations later
    title: 'Back to select workspace',
  },
  [STORE_SUMMARY]: {
    route: PUBLICATION_STORE,
    title: 'NAVIGATION.PUBLICATION_STORE',
  },
  [STORE_ORDER_COMPLETE]: {
    route: DASHBOARD,
    title: 'DASHBOARD_PAGE.TITLE',
  },
  [SUMMARY_ORDER_COMPLETE]: {
    route: VACANCY_LIST,
    title: 'PUBLISHING_OPTIONS.BUTTON.BACK_TO_JOB_ADS',
  },
  [STORE_COMPARISON_TABLE]: {
    route: PUBLICATION_STORE,
    title: 'PUBLICATION_STORE.COMPARISON_TABLE.GO_BACK',
  },
  [ME]: { route: DASHBOARD, title: 'DASHBOARD_PAGE.TITLE' },
  [ANY_ORDER_COMPLETE]: { route: DASHBOARD, title: 'DASHBOARD_PAGE.TITLE' },
  [PUBLISH_VACANCY]: { route: VACANCY_LIST, title: 'PUBLISHING_OPTIONS.BUTTON.BACK_TO_JOB_ADS' },
};

const createRouteHash = (prev: string | undefined, curr: string | undefined) =>
  shouldCheckPrevPage(curr) ? `${prev ? prev + '-' : ''}${curr ? curr : ''}` : curr ?? '';

const mapUrlToPageName = (url: string) => {
  if (url.includes(MY_USER_PROFILE)) {
    return ME;
  }
  if (url.includes(SELECT_WORKSPACE)) {
    return SELECT_WORKSPACE;
  }
  if (url.includes(ANY_VACANCY)) {
    return VACANCY_LIST;
  }
  if (url.includes(STORE_COMPARISON_TABLE)) {
    return STORE_COMPARISON_TABLE;
  }
  if (url.includes(SUMMARY_ORDER_COMPLETE)) {
    return SUMMARY_ORDER_COMPLETE;
  }
  if (url.includes(ANY_ORDER_COMPLETE)) {
    return ANY_ORDER_COMPLETE;
  }
  if (url.includes(STORE_SUMMARY)) {
    return STORE_SUMMARY;
  }
  return undefined;
};

const shouldCheckPrevPage = (curr: string | undefined) =>
  // routes that set different go back value depending on the previous page
  !![ME].find((route) => curr?.includes(route));
