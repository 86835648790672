import { combineLatest, filter, map, Observable, take } from 'rxjs';
import { Router, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectIsBoUser } from '@user/store/selectors/user.selectors';
import { selectActiveAccountMembershipCount } from '@mkp/account-membership/state';
import { AppConfig } from '@config/app.config';

export function hasMultipleWorkspacesGuard(): Observable<boolean | UrlTree> {
  const store = inject(Store);
  const router = inject(Router);

  return combineLatest([
    store.select(selectIsBoUser).pipe(filter((isBoUser) => !isBoUser)),
    store.select(selectActiveAccountMembershipCount),
  ]).pipe(
    filter(([_, count]) => count !== null),
    take(1),
    map(([, count]) => count > 0 || router.createUrlTree([AppConfig.routes.dashboard]))
  );
}
