import { HeaderItemType, TopHeader } from 'layout/state';

export class HeaderBuilder {
  private header: TopHeader = {
    hasTopHeader: true,
    isLight: false,
    leftBoxContent: [],
    rightBoxContent: [],
    name: undefined
  }

  withHeaderBackground(background: 'light' | 'dark') {
    this.header.isLight = background === 'light';
    return this;
  }

  withRightBoxItem<T = undefined>(item: HeaderItemType, options?: T) {
    this.header.rightBoxContent?.push({ type: item, options });
    return this;
  }

  withOptionalRightBoxItem<T = undefined>(item?: HeaderItemType, options?: T) {
    return item ? this.withRightBoxItem(item, options) : this;
  }

  withLefBoxItem<T = undefined>(item: HeaderItemType, options?: T) {
    this.header.leftBoxContent?.push({ type: item, options });
    return this;
  }

  withOptionalLeftBoxItem<T = undefined>(item?: HeaderItemType, options?: T) {
    return item ? this.withLefBoxItem(item, options) : this;
  }

  withName(name: string) {
    this.header.name = name;
    return this;
  }

  build() {
    if (!this.header.name) {
      throw new Error('Header name is required');
    }
    return this.header;
  }
}