import { LayoutInputParams, TopHeader } from 'layout/state';
import {
  cmsTopHeader,
  withBackButtonTopHeader,
  landingPageTopHeader,
  loggedInUserDefaultTopHeader,
  regFlowTopHeader,
  workspaceSelectTopHeader,
  summaryTopHeader,
} from './layout-header.config';
import {
  ANY_VACANCY,
  CLAIM_COMPANY,
  CMS_PAGE,
  CREATE_SUMMARY,
  LANDING_PAGE,
  MY_USER_PROFILE,
  SELECT_WORKSPACE,
  STORE_COMPARISON_TABLE,
  STORE_SUMMARY,
  SUMMARY_ORDER_COMPLETE,
  SUPPORT_CENTER,
  VERIFY_IDENTITY,
  urlMatchesAnyPage,
} from './layout-pages.helpers';
import { User } from '@user/shared/models';

export const getPageHeader = ({ url, isMobile, user }: LayoutInputParams) =>
  user ? getLoggedInUserHeader(url, isMobile, user) : getVisitorUserHeader(url, isMobile);

export const getLoggedInUserHeader = (url: string, isMobile: boolean, user: User): TopHeader => {
  const isBoUser = user.settings.displayType === 'customerService';
  if (url.includes(SELECT_WORKSPACE)) {
    return workspaceSelectTopHeader();
  }
  if (url.includes(SUPPORT_CENTER)) {
    return landingPageTopHeader(true);
  }
  if (url.includes(CMS_PAGE)) {
    return cmsTopHeader(true);
  }
  if (url.includes(CREATE_SUMMARY) && !url.includes(SUMMARY_ORDER_COMPLETE)) {
    return summaryTopHeader();
  }
  if (urlMatchesAnyPage(PAGES_WITH_BACK_BUTTON, url)) {
    return withBackButtonTopHeader();
  }
  if (urlMatchesAnyPage([VERIFY_IDENTITY, CLAIM_COMPANY], url)) {
    return regFlowTopHeader(isMobile);
  }
  return loggedInUserDefaultTopHeader(isMobile, isBoUser);
};

export const getVisitorUserHeader = (url: string, isMobile: boolean): TopHeader => {
  if (urlMatchesAnyPage([LANDING_PAGE, SUPPORT_CENTER], url)) {
    return landingPageTopHeader(false);
  }
  if (url.includes(CMS_PAGE)) {
    return cmsTopHeader(false);
  }
  return regFlowTopHeader(isMobile);
};

const PAGES_WITH_BACK_BUTTON = [
  ANY_VACANCY,
  MY_USER_PROFILE,
  STORE_SUMMARY,
  SUMMARY_ORDER_COMPLETE,
  STORE_COMPARISON_TABLE,
];
