import { inject } from '@angular/core';
import { concatLatestFrom, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { BreakpointObserverService } from '@mkp/shared/util-device';
import { layoutActions } from '@mkp/layout/actions';
import { combineLatest, Observable, of } from 'rxjs';
import { selectLoggedInUser } from '@user/store/selectors/user.selectors';
import { selectRouteUrl } from '@store/selectors';
import { LayoutInputParams, selectLayoutElements, selectTopHeader } from 'layout/state';
import { areLayoutElementsDifferent, getPageLayoutElements } from './helpers/layout-elements';
import { getPageHeader } from './helpers/layout-headers';
import { GoBackService } from '@app/services/go-back.service';
import { getGoBackValue } from './helpers/go-back.helpers';

export const handleLayoutElements = createEffect(
  (store = inject(Store), bos = inject(BreakpointObserverService)) =>
    getLayoutParams$(store, bos).pipe(
      map(getPageLayoutElements),
      concatLatestFrom(() => store.select(selectLayoutElements)),
      filter(([curr, prev]) => areLayoutElementsDifferent(prev, curr)),
      switchMap(([layoutElements]) => of(layoutActions.updateLayoutElements(layoutElements)))
    ),
  { functional: true }
);

export const handlePageHeader = createEffect(
  (store = inject(Store), bos = inject(BreakpointObserverService)) =>
    getLayoutParams$(store, bos).pipe(
      map(getPageHeader),
      concatLatestFrom(() => store.select(selectTopHeader)),
      filter(([curr, prev]) => curr.name !== prev.name),
      switchMap(([config]) => of(layoutActions.updateHeader(config)))
    ),
  { functional: true }
);

export const handleGoBackValue = createEffect(
  (store = inject(Store), goBackService = inject(GoBackService)) =>
    store.select(selectRouteUrl).pipe(
      distinctUntilChanged(),
      filter(Boolean),
      startWith(''),
      pairwise(),
      tap(([prev, curr]) => {
        const goBackValue = getGoBackValue(prev, curr);
        if (goBackValue) {
          goBackService.setRoute(goBackValue.route);
          goBackService.setTitle(goBackValue.title);
        }
      })
    ),
  { functional: true, dispatch: false }
);

const getLayoutParams$ = (
  store: Store,
  bos: BreakpointObserverService
): Observable<LayoutInputParams> =>
  combineLatest({
    url: store.select(selectRouteUrl).pipe(filter(Boolean), distinctUntilChanged()),
    isMobile: bos.isMobile$.pipe(distinctUntilChanged()),
    isTablet: bos.isTablet$.pipe(distinctUntilChanged()),
    user: store
      .select(selectLoggedInUser)
      .pipe(distinctUntilChanged((previous, current) => previous?.id === current?.id)),
  });
