import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { HeaderItem, LayoutElements, LayoutState, TopHeader } from 'layout/state';

export const layoutActions = createActionGroup({
  source: 'Layout',
  events: {
    'Update Layout Elements': props<LayoutElements>(),
    'Update Header Elements': props<{ leftBoxItems?: HeaderItem[], rightBoxItems?: HeaderItem[] }>(),
    'Update Header': props<TopHeader>(),
    'Update Layout': props<LayoutState>(),
    'Reset Layout': emptyProps(),
    'Show Navigation': emptyProps(),
    'Hide Navigation': emptyProps(),
    'Show Top Header': emptyProps(),
    'Hide Top Header': emptyProps(),
    'Collapse Navigation': props<{ isCollapsed: boolean }>(),
    'Toggle Navigation': emptyProps(),
  },
});
