import { LayoutElements, LayoutInputParams } from 'layout/state';
import { ElementBuilder } from './layout-element-config-builder';
import {
  ANY_VACANCY,
  ANY_ORDER_SUMMARY,
  CLAIM_COMPANY,
  CMS_PAGE,
  LANDING_PAGE,
  LOGIN_PAGE,
  MY_USER_PROFILE,
  ONBOARDING,
  SELECT_WORKSPACE,
  STORE_COMPARISON_TABLE,
  SUPPORT_CENTER,
  VERIFY_IDENTITY,
  urlMatchesAnyPage,
} from './layout-pages.helpers';

export const areLayoutElementsDifferent = (prev: LayoutElements, curr: LayoutElements) => {
  return prev.isNavigationCollapsed === undefined ||
  (
    prev.hasNavigation !== curr.hasNavigation ||
    prev.hasBorders !== curr.hasBorders ||
    prev.hasFooter !== curr.hasFooter ||
    prev.normalDocumentFlow !== curr.normalDocumentFlow ||
    prev.hasTopHeader !== curr.hasTopHeader
  );
};

export const getPageLayoutElements = ({ url, isMobile, isTablet, user}: LayoutInputParams): LayoutElements => {
  if (urlMatchesAnyPage(PAGES_WITHOUT_NAVIGATION, url)) {
    return new ElementBuilder().withTopHeader().withBorders(!isMobile).withFooter().build();
  }
  if (urlMatchesAnyPage(PUBLIC_PAGES, url)) {
    return new ElementBuilder().withTopHeader().withFooter().withNormalDocumentFlow().build();
  }

  if (urlMatchesAnyPage(REG_FLOW, url)) {
    return new ElementBuilder().withTopHeader().build();
  }
  return new ElementBuilder()
    .withNavigation(!!user)
    .withNavigationCollapsed(isMobile || isTablet)
    .withBorders(!!user && !isMobile)
    .withFooter()
    .withTopHeader()
    .build();
};

const PUBLIC_PAGES = [CMS_PAGE, LANDING_PAGE, SUPPORT_CENTER];

const PAGES_WITHOUT_NAVIGATION = [
  ANY_VACANCY,
  ANY_ORDER_SUMMARY,
  STORE_COMPARISON_TABLE,
  SELECT_WORKSPACE,
  MY_USER_PROFILE,
];

const REG_FLOW = [CLAIM_COMPANY, LOGIN_PAGE, ONBOARDING, VERIFY_IDENTITY];
