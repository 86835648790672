import { TopHeader, UserMenuHeaderItemOptions } from 'layout/state';
import { HeaderBuilder } from './layout-header-config-builder';

export const loggedInUserDefaultTopHeader = (isMobile: boolean, isBoUser: boolean): TopHeader =>
  new HeaderBuilder()
    .withHeaderBackground('dark')
    .withLefBoxItem('burger_menu')
    .withLefBoxItem(isBoUser ? 'header_search' : 'selected_workspace')
    .withOptionalRightBoxItem(!isMobile ? 'lang_menu' : undefined)
    .withRightBoxItem<UserMenuHeaderItemOptions>('user_menu')
    .withName('loggedInUserDefaultTopHeader')
    .build();

export const workspaceSelectTopHeader = (): TopHeader =>
  new HeaderBuilder()
    .withHeaderBackground('dark')
    .withLefBoxItem('one_logo')
    .withRightBoxItem('lang_menu')
    .withRightBoxItem('user_menu')
    .withName('workspaceSelectTopHeader')
    .build();

export const landingPageTopHeader = (isLoggedIn: boolean): TopHeader =>
  new HeaderBuilder()
    .withHeaderBackground('dark')
    .withLefBoxItem('one_logo')
    .withRightBoxItem('lang_menu')
    .withRightBoxItem(isLoggedIn ? 'dashboard_link' : 'login_link')
    .withName(`landingPageTopHeader`)
    .build();

export const cmsTopHeader = (isLoggedIn: boolean): TopHeader =>
  new HeaderBuilder()
    .withHeaderBackground('light')
    .withLefBoxItem('url_logos')
    .withLefBoxItem('logo_divider')
    .withLefBoxItem('one_logo')
    .withRightBoxItem('lang_menu', { platformStyle: !isLoggedIn })
    .withRightBoxItem(isLoggedIn ? 'user_menu' : 'login_link', { platformStyle: !isLoggedIn })
    .withName(`cms${isLoggedIn ? 'LoggedIn' : 'Visitor'}TopHeader`)
    .build();

export const regFlowTopHeader = (isMobile: boolean): TopHeader =>
  new HeaderBuilder()
    .withHeaderBackground('dark')
    .withLefBoxItem('one_logo')
    .withOptionalRightBoxItem(!isMobile ? 'platform_logos' : undefined)
    .withRightBoxItem('lang_menu')
    .withName(`regFlow${isMobile ? 'Mobile' : ''}TopHeader`)
    .build();

export const withBackButtonTopHeader = (): TopHeader =>
  new HeaderBuilder()
    .withHeaderBackground('dark')
    .withLefBoxItem('go_back')
    .withRightBoxItem('lang_menu')
    .withRightBoxItem('user_menu')
    .withName(`createVacancyTopHeader`)
    .build();

export const summaryTopHeader = (): TopHeader =>
  new HeaderBuilder()
    .withHeaderBackground('dark')
    .withRightBoxItem('lang_menu')
    .withRightBoxItem('user_menu')
    .withName(`summaryTopHeader`)
    .build();
